@mixin flex-center($dir, $justify: center) {
  display: flex;
  align-items: center;
  justify-content: $justify;
  flex-direction: $dir;
}

@mixin animateOnShow() {
  animation: difuse 1s ease forwards;

  @keyframes difuse {
    0% {
      // transform: translateX(-10px);
      opacity: 0;
    }
    100% {
      // transform: translateX(0);
      opacity: 1;
    }
  }
}
