footer {
  background-color: $light-grey;
  width: 100vw;
  height: auto;
  margin: 0;
  font-family: 'Montserrat', sans-serif;

  ul {
    margin: 0 auto;
    list-style: none;
    padding: 0;
  }

  .questions {
    margin-top: 0;
    margin-top: 0;
    width: 75%;

    h5 {
      padding: 5px;
      margin: 0;
    }
  }

  .social {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;

    .icon {
      width: 32px;
      height: 32px;
    }
  }

  .cr {
    text-align: center;
  }
}
