.status-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 40px;
  position: relative;
  background: linear-gradient(50deg, $primary, $secondary);

  .number {
    z-index: 9;
    font-weight: bold;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      margin: auto;
      width: 30px;
      left: -8px;
      top: 0;
      bottom: 0;
      height: 30px;
      border-radius: 50%;
      background: $light-grey;
    }
  }

  .progress {
    position: absolute;
    right: -1px;
    right: 0px;
    top: 0;
    height: 100%;
    border-radius: 0 10px 0 0;
    background-color: $light-grey;
    transition: 0.4s ease-out;
  }

  .s1 {
    width: 80%;
  }
  .s2 {
    width: 70%;
  }
  .s3 {
    width: 60%;
  }
  .s4 {
    width: 50%;
  }
  .s5 {
    width: 40%;
  }
  .s6 {
    width: 30%;
  }
  .s7 {
    width: 20%;
  }
  .s8 {
    width: 10%;
  }
}
