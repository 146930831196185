.genderWrapper {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'nav'
    'content';

  .genderTitle {
    grid-area: 'nav';

    h1 {
      font-size: 60px;
      margin: 20px;
      text-align: center;
    }
  }

  .btnWrapper {
    grid-area: 'content';
    @include flex-center(column);

    img {
      border: none;
      max-width: 200px;
      margin: 50px 150px 0;
      pointer-events: none;
    }

    button {
      border: none;
      border-radius: 30px;
      cursor: pointer;
      background-color: #ffffff00;
      transition: all 0.4s;

      h2 {
        font-size: 40px;
      }

      &:hover {
        background: $light-grey;
      }
    }
  }
}
