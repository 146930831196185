.spinner {
  border: 5px solid grey;
  border-top: 5px solid $primary;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 50px auto;
  animation: rotate 1s ease-in-out infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
