.justify {
  justify-items: center;
}

.list-wrapper {
  width: 95%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-content: center;
  margin: 0 auto;

  .list-input {
    height: 48px;
    border: 1px solid white;
    border-radius: 16px;
    min-width: 100px;
    margin: 5px 0 25px;
    outline: none;

    font-size: 15.3px;
    font-weight: bold;

    background-color: $light-grey;
    color: grey;
    font-family: 'Montserrat', sans-serif;
    position: relative;

    transition: 0.3s ease-out;
    word-break: break-all;
    padding: 10px;
    padding-left: 80px;

    &:hover {
      box-shadow: 0 0 0.4em #00000030;
      border: 1px solid $secondary;
    }

    img {
      position: absolute;
      left: -4px;
      top: -15px;
      pointer-events: none;
      border-radius: 5px;
      margin-right: 10px;
    }

    .logo {
      width: 80px;
    }
  }

  .active {
    background: linear-gradient(50deg, $primary, $secondary);
    color: white;
  }
}
