.baloonsContainer {
  display: grid;
  grid-template-columns: 1fr;
  width: 80%;
  margin: 30px 0;
  align-content: center;
  justify-items: center;

  .baloon {
    position: relative;
    padding: 0;
    margin: 10px auto;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .baloonImg {
    width: 100%;
    height: 100%;
  }

  .baloonText {
    position: absolute;
    z-index: 10;
    color: white;
    width: auto;
    padding: 10px;
    text-align: center;

    span {
      font-size: 14px;
      margin:0;
    }

    h2 {
      font-size: 24px;
      margin: 3px 0;
    }
  }
}

.profile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0;
  padding: 0 10px;

  .data {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin: 4px 10px;

    .dataImg {
      max-width: 50px;
    }

    .dataText {
      margin: 0 9px;

      h4 {
        margin: 0;
      }

      h2 {
        margin: 5px 0;
        font-size: 20px;
      }
    }
  }
}

.successContainer {
  @include flex-center(column);

  & > h1 {
    font-weight: 200;
    font-size: 20px;
  }
  h1 {
    text-align: center;
    font-size: 15px;
    margin: 5px;
  }
}

.slidersContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  .cont {
    @include flex-center(column);
    margin: 20px;

    .sliderbg {
      background: $light-grey;
      padding: 15px 20px;
      border-radius: 15px;
      margin:0;
    }

    .light {
      font-weight: 199;
    }

    .strong {
      font-weight: 599;
    }

    .slider {
      max-width: 300px;
      min-width: 260px;
      height: 40px;

      .percentage {
        width: 100%;
        margin-bottom: 10px;
        height: 10px;
      }

      .grasas {
        border-radius: 8px 0 0 8px;
        background-color: red;
      }

      .proteinas {
        background-color: blue;
      }

      .carbohidratos {
        border-radius: 0 8px 8px 0;
        background-color: green;
      }

      .water {
        background: red;
      }
    }

    h2 {
      margin: 8px;
    }

    .valueContainer {
      .value {
        margin-top: 2px;
        margin-bottom: 0;
        color: red;
      }
      h5 {
        margin: 0;
      }
    }

    .value {
      margin-top: 2px;
      color: red;
    }
    .data {
      font-weight: 600;
      margin-top: 10px;
    }

    .column {
      @include flex-center(column);
      margin: 0;
      padding: 0;
    }

    .row {
      @include flex-center(row);
    }
  }
}
