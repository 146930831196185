@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@import './base/colors';
@import './base/reset';
@import './base/mixins';
@import './components/footer';
@import './components/navigation';
@import './components/presentation';
@import './components/spinner';
@import './components/form';
@import './components/success';
@import './components/landing';
@import './components/genders';
@import './layout/grid';

@media (min-width: 465px) {
  .form-wrapper {
    .list-wrapper {
      width: 85%;
    }

    .fieldset {
      width: 450px;
    }
  }

  .text-input-img {
    width: 70px !important;
  }

  .text-input-input {
    margin-left: -50px !important;
  }

  .landingContainer {
    .nav {
      display: block;
      margin: 50px;
    }
    .textContainer {
      max-width: 400px;
      h1 {
        font-size: 40px;
      }
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 20px;
      }
    }
  }

  .slidersContainer {
    .cont {
      .sliderbg {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}

@media (min-width: 640px) {
  .landingContainer {
    .textContainer {
      max-width: 600px;
      h1 {
        font-size: 40px;
      }
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 20px;
      }
    }
  }

  .form-wrapper {
    .list-wrapper {
      width: 85%;
    }

    .fieldset {
      width: 600px;
    }
  }
}

@media (min-width: 760px) {
  .genderWrapper {
    .btnWrapper {
      @include flex-center(row);
    }
  }

  .form-wrapper {
    .fieldset {
      width: 700px;
      padding: 30px;
      margin-top: 40px;
      margin-bottom: 50px;
    }
  }
  .list-wrapper {
    .list-input {
    }
  }

  .baloonsContainer {
    grid-template-columns: repeat(3, 1fr);
    .baloon {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .profile {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 990px) {
  .baloonsContainer {
    transform: scale(1.2);
  }

  .landingContainer {
    .dishImg {
      display: block;
    }
  }

  .successContainer {
    h1 {
      font-size: 30px;
    }
  }

  .landingContainer {
    .textContainer {
      max-width: 500px;
      h1 {
        font-size: 50px;
      }
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 22px;
        line-height: 40px;
      }
    }
  }

  .form-wrapper {
    .fieldset {
      padding: 50px;
      margin-top: 80px;
      margin-bottom: 100px;
      width: 800px;
    }
  }

  .slidersContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .form-wrapper {
    .fieldset {
      width: 800px;
    }
  }

  .landingContainer {
    .textContainer {
      max-width: 600px;
      h1 {
        font-size: 60px;
      }
      h2 {
        font-size: 25px;
      }
      p {
        font-size: 30px;
        line-height: 50px;
      }
    }
  }
}

@media (min-width: 1500px) {
  .landingContainer {
    .textContainer {
      max-width: 900px;
    }
  }
}

@media (min-width: 1800px) {
  .landingContainer {
    .textContainer {
      max-width: 1200px;
    }
  }

  .checkbox-wrapper {
    width: 65%;
  }

  .form-wrapper {
    .fieldset {
      width: 1000px;
    }
  }
}
