@import './components/list';
@import './components/personal-info';
@import './components/statusbar';
@import './components/checkbox';
@import './components/backgrounds';

.form-wrapper {
  width: 100vw;
  height: 100vh;

  .title {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 0 30px;
    border-radius: 20px;
    background-color: $primary;
    height: 60px;
    @include flex-center(column);
    color: white;
    margin-bottom: 0;
    a {
      color: white;
    }
  }

  .subtitle {
    margin: 10px 0 40px;
    color: grey;
    padding: 0 5px;
    text-align: center;
  }

  .fieldset {
    @include animateOnShow();
    width: 340px;
    box-shadow: 0 0 0.7em #00000030;
    @include flex-center(column, flex-start);
    border-radius: 80px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
    background: #ffffff;
    padding: 20px 10px;

    .loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  form {
    width: 85%;
    input {
      border-radius: 5px;
      padding: 15px;
      width: 100%;
    }
  }
}

.buttons-wrapper {
  @include flex-center(row);
  width: 60%;
  margin: 0 auto;
  margin-bottom: 50px;

  button {
    margin: 5px;
    border: none;
    border-radius: 20px;
    font-weight: bold;
    height: 50px;
    min-width: 130px;
    max-width: 400px;
    width: 50%;
    transition: 0.3s ease-out;
    outline: $primary;

    &:hover {
      border: 2px solid $primary;
      background: linear-gradient(50deg, $primary, $secondary);
      color: white;
    }
  }

  .prev {
    background: linear-gradient(50deg, $light-grey, #fff);
    border: 2px solid $light-grey;
    color: grey;
  }

  .next {
    background: linear-gradient(50deg, $light-grey, #fff);
    border: 2px solid $primary;
    color: $primary;
  }

  .btn-active {
    background: $primary;
    color: white;
  }

  .btn-inactive {
    background-color: $light-grey;
    color: black;
  }
}

.under-form {
  @include flex-center(column);
}
