.bgImg {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -9;
}

.bg-form-mobile {
  background-image: url('../../images/third/background/cbg3.png');
}
.bg-form-tablet {
  background-image: url('../../images/third/background/cbg3.png');
}
.bg-form-desktop {
  background-image: url('../../images/third/background/cbg3.png');
}

.bg-gender-mobile {
  background-image: url('../../images/second/background/cbg2.png');
}
.bg-gender-tablet {
  background-image: url('../../images/second/background/cbg2.png');
}
.bg-gender-desktop {
  background-image: url('../../images/second/background/cbg2.png');
}

.bg-landing-mobile {
  background-image: url('../../images/first/background/cbg1.png');
}
.bg-landing-tablet {
  background-image: url('../../images/first/background/cbg1.png');
}
.bg-landing-desktop {
  background-image: url('../../images/first/background/cbg1.png');
}
