.presentation {
  margin: 0 auto;
  width: 95vw;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;

    h1 {
      font-size: 40px;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;

    button {
      min-width: 240px;
      min-height: 240px;
      border: 3px solid white;
      margin: 20px 80px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s ease-out;
      position: relative;
      color: white;
      font-weight: bold;

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        opacity: 0.4;
      }

      &:hover {
        transform: scale(1.2);
      }

    }
  }

  .info {
    h5 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    p {
      max-width: 800px;
      display: flex;
      align-items: flex-start;
      align-items: center;
    }
  }
}
