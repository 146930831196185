.personal-info-form {
  margin-bottom: 10px;
  width: 100%;

  .text-input-wrapper {
    display: grid;
    grid-template-columns: minmax(70px, 120px) 1fr;
    grid-template-areas: 'img input';
    min-width: 290px;
    align-items: center;

    .text-input-img {
      margin: 0 auto;
      grid-area: img;
      display: block;
      margin-left: -20px;
      width: 60px;
    }

    .text-input-input {
      grid-area: input;
      margin-left: -75px;

      input {
        border-radius: 5px;
        border: 1px solid grey;
        font-size: 18px;
        height: 60px;
        margin: 20px 0;
      }

      .custom-input {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .units {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 10px;
          min-width: 30px;
          border-radius: 5px 5px;
          margin: 0 6px 0 3px;
          text-align: center;
        }
      }
    }
  }
}
