nav {
  background: none;
  width: 95vw;
  height: auto;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-family: 'Montserrat', sans-serif;

  .img-wrapper {
    width: 80px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .nav-btn-container {
    display: none;

    .btn {
      margin: 5px;
      border-radius: 8px;
      padding: 0 15px;
      border: 2px solid white;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
      transition: 0.3s ease-out;
    }

    .contact-btn {
      background: $primary;
      &:hover {
        background: white;
        color: $primary;
        border: 2px solid $primary;
      }
    }

    .login-btn {
      background: $light-blue;
      &:hover {
        border: 2px solid $light-blue;
        color: $light-blue;
        background: white;
      }
    }
  }
  // .nav-buttons-container {
  //   display: none;
  // }

  ul {
    list-style: none;
    padding: 0;
    z-index: 99;
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    height: auto;
    background: white;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    li {
      width: 100%;
      text-align: center;
      margin: 10px 0;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .menuBtn {
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
      width: 50px;
      height: 50px;
      border: 2px solid white;
      border-radius: 5px;
    }
  }
}
