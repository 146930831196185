* {
  box-sizing: border-box;
}

fieldset {
  border: none;
}

::selection {
  background: $primary;
  color: white;
  padding: 5px;
}

body,
html {
  margin: 0;
  padding: 0;
}
