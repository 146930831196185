.checkbox-wrapper {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  align-content: center;
  margin: 0 auto;
  padding: 0 20px 30px;

  .checkbox {
    border: 1px solid white;
    border-radius: 16px;
    padding-left: 0;
    min-width: 100px;
    @include flex-center(row, space-between);
    background-color: $light-grey;
    color: grey;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    transition: 0.3s ease-out;
    word-break: break-all;
    padding: 10px;
    margin-top: 30px;
    outline: none;
    padding: 0;
    height: 48px;

    &:hover {
      box-shadow: 0 0 0.4em #00000030;
      border: 1px solid $secondary;
    }

    img {
      border-radius: 5px;
      margin-right: 10px;
    }

    .logo {
      width: 80px;
    }

    h3 {
      margin: 0 auto;
      width: calc(100% - 50px);
    }

    input {
      border: 1px solid red;
      height: 20px;
    }

    label {
      display: block;
      border: 1px solid red;
      text-align: center;
      height: 100%;
      width: 100%;
    }
  }

  .active {
    background: linear-gradient(50deg, $primary, $secondary);
    color: white;
  }
}
