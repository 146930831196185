.landingContainer {
  position: relative;
  margin: 0 auto;
  width: 90%;
  overflow: hidden;

  .dishImg {
    display: none;
    position: fixed;
    bottom: -35px;
    right: 15%;
    transition: all 1.5s;
    z-index: -1;
    width: 400px;

    img {
      width: 800px;
    }
  }

  .nav {
    grid-area: nav;
    @include flex-center(row);

    margin: 50px 0;
  }

  .textContainer {
    grid-area: content;
    margin-top: 10vh;
    max-width: 300px;

    h1 {
      margin: 10px 0;
    }

    p {
      line-height: 26px;
      margin: 0 0 90px 0;
      margin-top: 20px;
    }

    .beginBtn {
      background-color: $light-blue;
      border: none;
      border-radius: 10px;
      padding: 0 60px;
      margin-bottom: 40px;
      color: white;
      cursor: pointer;

      h2 {
        padding: 0;
        font-weight: bold;
      }
    }
  }
}
